// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/mrcall/littleman/assistance_transparent_bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
#onboarding-support-section[data-v-68d9b7c8] {
  display: flex;
  flex-direction: column;
  margin: 0 auto auto auto;
  padding: 0;
}
#onboarding-support-section #header[data-v-68d9b7c8] {
  width: 100%;
}
#onboarding-support-section #header #icon[data-v-68d9b7c8] {
  width: 80px;
  height: 80px;
  mix-blend-mode: multiply;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
}
#onboarding-support-section #header #title[data-v-68d9b7c8] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1em;
}
@media screen and (max-width: 640px) {
#onboarding-support-section[data-v-68d9b7c8] {
    padding: 0 0 0 0;
}
#onboarding-support-section #header[data-v-68d9b7c8] {
    display: none;
}
}
#onboarding-support-section #content[data-v-68d9b7c8] {
  width: 100%;
  /* h5/regular-400/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 1em;
  /* or 120% */
  /* Lara/Global/textSecondaryColor */
  color: #6C757D;
  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
#onboarding-support-section #content #first-part[data-v-68d9b7c8] {
  padding-top: 2em;
  padding-bottom: 2em;
}
#onboarding-support-section #content #second-part .p-button[data-v-68d9b7c8] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8em;
  line-height: 1em;
  color: #6C757D;
  background: transparent;
  border: 1px solid #6C757D;
  padding: 0.5rem 1em;
  margin: 0.2em 0.2em 0.2em auto;
  border-radius: 33px;
}
#onboarding-support-section #content #second-part .p-button[data-v-68d9b7c8]:hover {
  background: #6C757D;
  color: #FFFFFF;
}
@media screen and (max-width: 640px) {
#onboarding-support-section #content #second-part .p-button[data-v-68d9b7c8] {
    width: 100% !important;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
