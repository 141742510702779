// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mrcall-confirm-dialog .visibility-reject-button-off {
  visibility: hidden ;
}
.mrcall-confirm-dialog div.p-dialog-header-icons {
  visibility: hidden ;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
