// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pre-footer[data-v-0ee881e3] {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.content[data-v-0ee881e3] {
  max-width: 800px;
  margin: auto;
}
@media screen and (max-width: 640px) {
.content[data-v-0ee881e3] {
    margin: 2em auto auto auto;
    padding: 1em 0 1em 0 !important;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
