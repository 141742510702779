// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/mrcall/logo_menu_mrcall.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.mrcall-sign-content[data-v-662d1d8e] {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
}
@media screen and (max-width: 640px) {
.mrcall-sign-content[data-v-662d1d8e] {
    background: #FFFFFF;
}
.mrcall-sign-content .headinglogobox[data-v-662d1d8e] {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    gap: 8px;
    position: relative;
    width: 100%;
    height: 80px;
    left: 0;
    top: 0;
}
.mrcall-sign-content .headinglogobox .mrcall-title-logo[data-v-662d1d8e] {
    width: 112.68px;
    height: 32px;
    margin: auto;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.mrcall-sign-content .sign-central-window[data-v-662d1d8e] {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 24px;
    margin-bottom: auto;
    gap: 24px;
    position: relative;
    width: 100%;
    left: 0;
    /* Lara/Root/surface-card */
    background: #FFFFFF;
    border-radius: 6px;
}
.mrcall-sign-content .sign-central-window #title[data-v-662d1d8e] {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24.5px;
    line-height: 30px;
    color: #495057;
}
}
@media screen and (min-width: 640px) {
.mrcall-sign-content[data-v-662d1d8e] {
    background: #E5E5E5;
}
.mrcall-sign-content .headinglogobox[data-v-662d1d8e] {
    position: relative;
    width: 225.35px;
    height: 64px;
    left: calc(50% - 225.35px/2 - 0.32px);
    top: 18px;
}
.mrcall-sign-content .headinglogobox .mrcall-title-logo[data-v-662d1d8e] {
    width: 100% ;
    height: 100% ;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
}
.mrcall-sign-content .sign-central-window[data-v-662d1d8e] {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px;
    gap: 24px;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    width: 556px;
    height: 510px;
    left: calc(50% - 556px / 2 - 0.32px);
    /* Lara/Root/surface-card */
    background: #FFFFFF;
    /* cardShadow */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
}
.mrcall-sign-content .sign-central-window #title[data-v-662d1d8e] {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24.5px;
    line-height: 30px;
    color: #495057;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
