// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/mrcall/icons/warning_modal_dialog.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/images/mrcall/icons/error_modal_dialog.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/images/mrcall/icons/success_modal_dialog.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/images/mrcall/icons/check_icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.inputboxtitle[data-v-02324345] {
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 1em 0 0.4em 0;
  color: #495057;
}
.inputboxsubtitle[data-v-02324345] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 0.4em 0 0 0;
  color: #495057;
}
.inputboxsubtitle[data-v-02324345] a:link {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-02324345] a:visited {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-02324345] a:hover {
  color: #0F110C;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-02324345] a:active {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxerror[data-v-02324345] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 0.4em 0 0 0;
  color: red;
}
.modal_dialog_content[data-v-02324345] {
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 auto;
}
@media screen and (max-width: 640px) {
.modal_dialog_content[data-v-02324345] {
    width: 100%;
}
}
@media screen and (min-width: 640px) {
.modal_dialog_content[data-v-02324345] {
    width: 450px;
}
}
.modal_dialog_content .warning-icon[data-v-02324345] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  width: 5em;
  height: 5em;
  margin: 0 auto 0 auto;
}
.modal_dialog_content .error-icon[data-v-02324345] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) no-repeat;
  width: 5em;
  height: 5em;
  margin: 0 auto 0 auto;
}
.modal_dialog_content .success-icon[data-v-02324345] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) no-repeat;
  width: 5em;
  height: 5em;
  margin: 0 auto 0 auto;
}
.modal_dialog_content .modal_dialog_title[data-v-02324345] {
  margin: 0 auto 0 auto;
  text-align: center;
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  color: #495057;
}
.modal_dialog_content .modal_dialog_subtitle[data-v-02324345] {
  margin: 0 auto 0 auto;
  text-align: center;
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  padding-top: 1em;
  font-size: 1em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  color: #495057;
}
.modal_dialog_content .modal_dialog_message[data-v-02324345] {
  padding: 3em 1em 1em 1em;
  margin: 0 auto 0 auto;
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  color: #495057;
}
.modal-dialog-footer[data-v-02324345] {
  display: flex;
}
@media screen and (max-width: 640px) {
.modal-dialog-footer[data-v-02324345] {
    flex-direction: column;
}
}
@media screen and (min-width: 640px) {
.modal-dialog-footer[data-v-02324345] {
    flex-direction: row;
}
}
.modal-dialog-footer .p-button[data-v-02324345] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
}
@media screen and (min-width: 640px) {
.modal-dialog-footer .button-left[data-v-02324345] {
    margin-right: auto;
    padding-right: 1em;
}
}
.modal-dialog-footer .button-left .p-button-left.p-button[data-v-02324345] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
  color: #6C757D;
  background: transparent;
  border: 1px solid #6C757D;
  padding: 0.5rem 1em;
  margin: 0.2em 0.2em 0.2em auto;
  border-radius: 33px;
}
.modal-dialog-footer .button-left .p-button-left.p-button[data-v-02324345]:hover {
  background: #6C757D;
  color: #FFFFFF;
}
.plans[data-v-02324345] {
  background: #FFFFFF;
  width: 100% ;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1em;
}
.plans .plans-content[data-v-02324345] {
  margin: auto;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: 0 1em 1em 1em;
}
.plans .plans-content .plans-heading[data-v-02324345] {
  padding: 1em 0 2em 0;
  text-align: center;
}
.plans .plans-content .plans-heading .plans-title[data-v-02324345] {
  color: #495057;
  font-weight: 700;
  font-size: 1.7em;
  margin: auto auto auto auto;
  line-height: 1.2em;
}
.plans .plans-content .plans-heading .plans-subtitle[data-v-02324345] {
  color: #495057;
  font-size: 0.8em;
  font-weight: 400;
  margin: auto auto 0.5em auto;
}
.plans .plans-content .plans-heading .plans-highlight[data-v-02324345] {
  color: #0068FF;
  margin: 2em auto 0.5em auto;
}
.plans .plans-content .plans-body[data-v-02324345] {
  display: flex;
}
.plans .plans-content .plans-body .plans-item[data-v-02324345] {
  border: solid;
  border-color: #D7DCE2;
  border-radius: 6px;
  border-width: thin;
  padding: 1.5em 1em 0.5em 1em;
  overflow: visible;
}
.plans .plans-content .plans-body .plans-item .plan-item-title[data-v-02324345] {
  color: #495057;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}
.plans .plans-content .plans-body .plans-item .plan-item-highlight[data-v-02324345] {
  color: #0068FF;
  font-size: 0.8em;
}
.plans .plans-content .plans-body .plans-item .plan-item-pricing[data-v-02324345] {
  color: #495057;
  display: flex;
  margin: 1em 0 1em auto;
  padding: 0 0 0 0;
  flex-direction: row;
}
.plans .plans-content .plans-body .plans-item .plan-item-pricing .plan-item-currency[data-v-02324345] {
  font-size: 1.8em;
  margin: auto 0 0 0;
}
.plans .plans-content .plans-body .plans-item .plan-item-pricing .plan-item-number[data-v-02324345] {
  font-size: 1.8em;
  margin: auto 0.2em 0 0;
}
.plans .plans-content .plans-body .plans-item .plan-item-pricing .plan-item-periodicity[data-v-02324345] {
  font-size: 0.8em;
  margin: auto 0 0 0;
}
.plans .plans-content .plans-body .plans-item .plan-item-button[data-v-02324345] {
  margin: 0 0 1em 0;
}
.plans .plans-content .plans-body .plans-item .plan-item-button .p-button[data-v-02324345] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8em;
  line-height: 1em;
}
.plans .plans-content .plans-body .plans-item .plan-item-functionalities[data-v-02324345] {
  color: #495057;
}
.plans .plans-content .plans-body .plans-item .plan-item-functionalities .plan-item-functionalities-title[data-v-02324345] {
  font-size: 1.2em;
  margin-bottom: 1em;
}
.plans .plans-content .plans-body .plans-item .plan-item-functionalities .plan-item-functionality[data-v-02324345] {
  display: flex ;
  flex-direction: row;
  font-size: 1em;
  margin-bottom: 0.5em;
}
.plans .plans-content .plans-body .plans-item .plan-item-functionalities .plan-item-functionality .check-icon[data-v-02324345] {
  margin: 0.1em;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) no-repeat;
  width: 1em;
  height: 1em;
  margin-right: 0.8em;
}
.plans .plans-content .plans-body .plans-item .plan-item-functionalities .plan-item-functionality .feature[data-v-02324345] {
  width: 100%;
}
@media screen and (max-width: 640px) {
.plans .plans-content .plans-body[data-v-02324345] {
    flex-direction: column;
}
.plans .plans-content .plans-body .plans-item[data-v-02324345] {
    margin: 0 0.5em 0.5em 0.5em;
}
}
@media screen and (min-width: 640px) {
.plans .plans-content .plans-body[data-v-02324345] {
    flex-direction: row;
}
.plans .plans-content .plans-body .plans-item[data-v-02324345] {
    max-width: 18em;
    margin: 0.5em;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
