<template xmlns="http://www.w3.org/1999/html">
  <div class="signin-signup-form">
    <Dialog :modal="true" :closable="false" :closeOnEscape="false" v-model:visible="showMessage" :breakpoints="{ '960px': '80vw' }" :style="{ width: '40vw' }" position="top">
      <div class="p-d-flex p-ai-center p-dir-col p-pt-6 p-px-3">
        <div v-if="errorDialogTitle === null">
          <div class="text-center mb-4">
            <i id="response-message-success-symbol" class="pi pi-check-circle"></i>
          </div>

          <div class="text-center">
            <h5 class="mb-3 font-bold md:text-3xl text-2xl text-center">{{t('components.templates.sign.signup.registrationSuccess')}}</h5>
            <p :style="{lineHeight: 1.5, textIndent: '1rem'}">
              {{t('components.templates.sign.signup.accountRegistered')}}: <b>{{email}}</b> ; {{t('components.templates.sign.signup.activationInstructions')}}.
            </p>
          </div>
        </div>
        <div v-else>
          <div class="text-center mb-4">
            <i class="pi pi-check-circle" :style="{fontSize: '5rem', color: 'red' }"></i>
          </div>
          <div class="text-center">
            <h5 class="mb-3 font-bold md:text-3xl text-2xl text-center">{{ errorDialogTitle }}</h5>
            <div v-if="errorType === 'USER_ALREADY_EXISTS'">
              <p :style="{lineHeight: 1.5, textIndent: '1rem'}">
                {{errorDialogTextBefore}}: <b>{{email}}</b>
                <br>
                {{errorDialogTextAfter}}
              </p>
              <Button :label="t('components.templates.sign.signup.errorUserAlreadyExistsAccessLink')" @click="router.replace('/login')" class="p-button-text md:w-auto py-3 px-6 w-full"/>
            </div>
            <div v-else>
              <p :style="{lineHeight: 1.5, textIndent: '1rem'}">
                {{errorDialogTextBefore}}: <b>{{email}}</b>
                <br>
                {{errorDialogTextAfter}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div>
          <div v-if="errorDialogTitle === null">
            <Button label="OK" @click="router.replace('/')" class="p-button-text md:w-auto py-3 px-6 w-full" />
          </div>
          <div v-else>
            <Button :label="t('components.templates.sign.signup.errorDismissButton')" @click="toggleDialog" class="p-button-text md:w-auto py-3 px-6 w-full"/>
          </div>
        </div>
      </template>
    </Dialog>

    <div class="p-d-flex p-jc-center">
      <div class="card">
        <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
          <div class="p-field mb-3">
            <div class="inputboxtitle">
              {{t('components.templates.sign.signup.emailInputboxTitle')}}
            </div>
            <IconField>
              <InputText id="email"
                         class="pl-4 text-sm"
                         :placeholder="t('components.templates.sign.signup.emailInputboxPlaceholder')"
                         v-model="v$.email.$model"
                         :class="{'p-invalid':v$.email.$invalid && submitted}" aria-describedby="email-error"/>
              <InputIcon class="pi pi-envelope pr-4 text-lg" />
            </IconField>
            <span v-if="v$.email.$error && submitted">
                <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
                <small class="p-error">{{error.$message}}</small>
                </span>
            </span>
            <small v-else-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response" class="p-error">{{v$.email.required.$message.replace('Value', 'Email')}}</small>
          </div>
          <div class="p-field">
            <div class="inputboxtitle">
              {{t('components.templates.sign.signup.passwordInputboxTitle')}}
            </div>
            <Password
                :placeholder="t('components.templates.sign.signup.passwordInputboxPlaceholder')"
                :promptLabel="t('components.templates.sign.signup.formText.passwordPromptLabel')"
                :weakLabel="t('components.templates.sign.signup.formText.passwordWeakLabel')"
                :mediumLabel="t('components.templates.sign.signup.formText.passwordMediumLabel')"
                :strongLabel="t('components.templates.sign.signup.formText.passwordStrongLabel')"
                id="password" v-model="v$.password.$model" :class="{'p-invalid':v$.password.$invalid && submitted}" toggleMask>
              <template #header>
                <h6>{{t('components.templates.sign.signup.formText.qualityPasswordSubtitle')}}</h6>
              </template>
              <template #footer="sp">
                {{sp.level}}
                <Divider />
                <p class="p-mt-2">{{t('components.templates.sign.signup.formText.suggestions')}}</p>
                <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                  <li>{{t('components.templates.sign.signup.formText.oneLowecase')}}</li>
                  <li>{{t('components.templates.sign.signup.formText.oneUppercase')}}</li>
                  <li>{{t('components.templates.sign.signup.formText.oneNumeric')}}</li>
                  <li>{{t('components.templates.sign.signup.formText.oneSymbol')}}</li>
                  <li>{{t('components.templates.sign.signup.formText.atLeastNChars')}}</li>
                </ul>
              </template>
            </Password>
            <small v-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">
              <div
                  v-for="error in v$.password.$errors"
                  :key="error"
              >
                {{ error.$message.replace('Value', 'Password') }}
              </div>
            </small>
          </div>
          <div class="p-field mb-3">
            <Button class="signin-signup-button-text" type="submit" :label="t('components.templates.sign.signup.submitButton')" />
          </div>
        </form>
      </div>

      <div class="p-field mb-3">
        <div class="text-center" >
          <span class="companion-text-smaller">
            {{t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.firstStatement')}}
          </span>
          <span class="companion-text-smaller">&nbsp;</span>
          <a class="link-smaller" :href="t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.terms.link')" download>
            <b>
              {{t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.terms.text')}}
            </b>
          </a>
          <span class="companion-text-smaller">&nbsp;</span>
          <span class="companion-text-smaller">
            {{t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.connective')}}
          </span>
          <span class="companion-text-smaller">&nbsp;</span>
          <a class="link-smaller" :href="t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.privacy.link')" download>
            <b>
              {{t('components.templates.sign.signup.temsAndPrivacyPolicyStatement.privacy.text')}}
            </b>
          </a>
        </div>
      </div>
      <div class="p-field mb-3">
        <div class="text-center">
          <span class="companion-text">
            {{t('components.templates.sign.signup.signinLinkCompanionText')}}
          </span>
          <span class="companion-text">&nbsp;</span>
          <router-link to="/signin" class="link">{{t('components.templates.sign.signup.signinLinkLabel')}}</router-link>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="less">
@import "../../../assets/style/colors";
@import '../../../assets/style/components/templates/sign/login';
</style>

<script>
import {email, minLength, required} from "@vuelidate/validators"
import {useVuelidate} from "@vuelidate/core"
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'
import {useI18n} from "vue-i18n";
import Tr from "@/i18n/translation"

export default {
  setup() {
    const v$ = useVuelidate()
    const router = useRouter()
    const store = useStore()
    const { t } = useI18n()
    return {
      t,
      v$,
      store,
      router
    }
  },
  data() {
    return {
      email: '',
      password: '',
      errorType: '',
      errorDialogTitle: null,
      errorDialogTextBefore: null,
      errorDialogTextAfter: null,
      submitted: false,
      showMessage: false,
    }
  },
  validations() {
    return {
      email: {
        required,
        email
      },
      password: {
        required,
        minLengthValue: minLength(8),
      }
    }
  },
  created() {
  },
  mounted() {
    const lang = this.$route.query?.lang;
    if(lang) {
      Tr.switchLanguage(lang)
    }
  },
  methods: {
    async handleSubmit(isFormValid) {
      this.submitted = true;

      if (!isFormValid) {
        return;
      }

      try {
        await this.store.dispatch('signup', {
          email: this.email,
          password: this.password
        })
      } catch (err) {
        console.debug(err)
        if(err.message.includes("auth/email-already-in-use")) {
          this.errorDialogTitle = this.t('components.templates.sign.signup.errorUserAlreadyExistsTitle')
          this.errorDialogTextBefore = this.t('components.templates.sign.signup.errorUserAlreadyExistsMessageFirstPart')
          this.errorDialogTextAfter = this.t('components.templates.sign.signup.errorUserAlreadyExistsMessageSecondPart')
          this.errorType = "USER_ALREADY_EXISTS"
        } else if(err.message.includes("auth/network-request-failed")) {
          this.errorDialogTitle = this.t('components.templates.sign.signup.errorNetworkTitle')
          this.errorDialogTextBefore = this.t('components.templates.sign.signup.errorNetworkMessageFirstPart')
          this.errorDialogTextAfter = this.t('components.templates.sign.signup.errorNetworkMessageSecondPart')
          this.errorType = "NETWORK_ERROR"
        } else {
          this.errorDialogTitle = this.t('components.templates.sign.signup.loginFailedGenericTitle')
          this.errorDialogTextBefore = this.t('components.templates.sign.signup.registrationFailedMessage')
          this.errorDialogTextAfter = err.message
          this.errorType = ""
        }
      }

      this.toggleDialog();
    },
    toggleDialog() {
      this.$gtag.event('sign_up', {
        'user_email': this.email,
      });
      this.showMessage = !this.showMessage;

      if(!this.showMessage) {
        this.resetForm();
      }
    },
    resetForm() {
      this.email = '';
      this.password = '';
      this.errorType = '';
      this.errorDialogTitle = null ;
      this.errorDialogTextBefore = null ;
      this.errorDialogTextAfter = null ;
      this.submitted = false;
    }
  }
}
</script>