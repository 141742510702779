<template>
  <ProgressBar v-show="showProgressBar" mode="indeterminate" style="height: .3em"/>
  <div v-if="user">
    <div class="businesses-toolbar">
      <Paginator
          :template="{
                '640px': 'PrevPageLink CurrentPageReport NextPageLink',
        '960px': 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
        '1300px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown RowsPerPageDropdown'
    }"
          :alwaysShow="false"
          :rows="size"
          v-model:first="first"
          :rowsPerPageOptions="[10,20,30,50,100]"
          :totalRecords="totalHits"
          @page="onPage($event)">
      </Paginator>
      <template v-if="isAdmin">
        <Toolbar class="px-5">
          <template #start>
            <!--
            <Button
                :label="$t('components.businesses.createNewBusiness')"
                icon="pi pi-plus" iconPos="right"
                @click="createBusiness()" />
            -->
          </template>

          <template #end>
            <div>
              <MultiSelect
                  v-model="selectedSubscriptionStatus"
                  :options="subscriptionStatusValueLabelMap"
                  optionLabel="name"
                  @change="onSortFieldsChange"
                  :maxSelectedLabels="3"
                  placeholder="Select field to sort results"
                  class="mx-5"
              />
              <Dropdown v-model="selectedSortOption" :options="sortOptions"
                        @change="onSortOrderChange"
                        optionLabel="name" optionValue="value" placeholder="Sort by" />
            </div>
          </template>
        </Toolbar>
      </template>
      <template v-if="isAdmin || totalHits > size">
        <Toolbar class="px-5">
          <template #start>
            <div>
              <Dropdown v-model="matchFieldMapValue" :options="matchFieldMap"
                        optionLabel="name" optionValue="value" placeholder="Filter by" />
              <i class="pi p-toolbar-separator mr-2" />
              <IconField>
                <InputText
                    class="w-full"
                    :disabled="false"
                    type="string"
                    v-on:keyup.enter="onUpdateMatchFieldMapQueryValue()"
                    v-model="matchFieldMapQueryValue"
                />
                <InputIcon class="pi pi-search" />
              </IconField>
            </div>
          </template>
          <template #end>
            <Button
                :label="$t('components.businesses.addFieldMatch')"
                icon="pi pi-search-plus" iconPos="right"
                @click="onUpdateMatchFieldMapQueryValue()" />
          </template>
        </Toolbar>
      </template>
    </div>
    <div class="business-table-container" v-for="([key, business], index) in businesses" :key="key">
      <div v-if="showBusinessOverlay(business)" class="business-table-card-overlay">
        <div class="overlay-button-panel">
          <div class="text-center">
            <div>
              <Button
                  :label="$t('components.businesses.onboardinConfigureAndTest')" icon="pi pi-pencil" iconPos="right"
                  @click="router.push(`/onboardingmakeatestcall?id=${business.businessId}`)"
              />
            </div>
            <div>
              <Button
                  :label="$t('components.businesses.onboardingActivateAssistant')" icon="pi pi-pencil" iconPos="right"
                  @click="router.push(`/onboardingchooseplan?id=${business.businessId}`)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="business-table-card">
        <div class="business">
          <div class="business-title">
            <p class="title text-center mb-4 md:text-5xl text-4xl">{{ business.companyName }}</p>
          </div>
          <div class="business-content">
            <div class="central-information-panel">
              <div v-if="false" class="overlay">
              </div>
              <div class="content overflow-x-auto relative mb-4">
                <div class="md:block hidden">
                  <table class="w-full border-top-1 table-separator-top">
                    <tr v-if="business.businessId && isAdmin">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2"><span class="title text-base">{{ $t('components.businesses.form.businessId') }}</span></td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">{{ business.businessId }}</td>
                    </tr>
                    <tr v-if="business.nickname">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2"><span class="title text-base">{{ $t('components.businesses.form.nickname') }}</span></td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">{{ business.nickname }}</td>
                    </tr>
                    <tr v-if="business.emailAddress">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2"><span class="title text-base">{{ $t('components.businesses.form.email') }}</span></td>
                      <td class="py-2 border-bottom-1 table-separator-bottom align-right-td">{{ business.emailAddress }}</td>
                    </tr>
                    <tr v-if="business.businessPhoneNumber">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2">
                        <span class="title text-base">{{ $t('components.businesses.form.businessPhoneNumber') }}</span>
                        <span style="color: #F7941F;">
                    {{ isBusinesPhoneNumberVerified(business) ? '' :
                            ' (' + $t('components.businesses.businessPhoneNumberVerified') + ')' }}
                  </span>
                      </td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">{{ business.businessPhoneNumber }}</td>
                    </tr>
                    <tr v-if="business.serviceNumber">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2">
                  <span class="title text-base">
                    <div v-if="isWebView">
                      {{ $t('components.businesses.form.serviceNumber') }}
                      {{ isTemporaryTestServiceNumber(business) ? ' (' + $t('components.businesses.enabledForTest') + ')' : '' }}
                    </div>
                    <div v-else>
                      {{ $t('components.businesses.form.serviceNumber') }}
                      {{ isTemporaryTestServiceNumber(business) ? ' (' + $t('components.businesses.onlyForTest') + ')' : '' }}
                    </div>
                  </span>
                        <span style="color: #F7941F;">
                    {{ ((subscriptionInfo[business.businessId]?.status === 'TRIALING' ||
                                subscriptionInfo[business.businessId]?.status === 'ACTIVE') &&
                            (subscriptionInfo[business.businessId]?.testNumber === true)
                        ) ? $t('components.businesses.numberNotAssignedContactSupport') : '' }}
                  </span>
                      </td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">
                        <div v-if="isWebView">
                          <b>{{ getCleanServiceNumber('', business.serviceNumber) }}</b>
                        </div>
                        <div v-else>
                          <a :href="getCleanServiceNumber('tel:', business.serviceNumber)">
                            <b>{{ getCleanServiceNumber('', business.serviceNumber) }}</b>
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="business.template && templates[business.template]">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2">
                        <span class="title text-base">{{ $t('components.businesses.form.plan') }}</span>
                      </td>
                      <td class="py-2 border-bottom-1 table-separator-bottom align-right-td">
                  <span class="title text-base">
                    {{ templates[business.template].humanName }}
                  </span>
                      </td>
                    </tr>
                    <tr v-if="subscriptionInfo[business.businessId]?.status">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2">
                        <span class="title text-base">{{ $t('components.businesses.form.subscriptionStatus') }}</span>
                      </td>
                      <td class="py-2 border-bottom-1 table-separator-bottom align-right-td">
                  <span class="title text-base">
                    {{ $t(`components.businesses.form.subscriptionStatusLabels.${subscriptionInfo[business.businessId].status}`) }}
                  </span>
                      </td>
                    </tr>
                    <tr v-if="subscriptionInfo[business.businessId]?.status === 'ACTIVE' &&
                            ! subscriptionInfo[business.businessId]?.secondsToExpiration &&
                              subscriptionInfo[business.businessId]?.currentPeriodEnd">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2"><span class="title text-base">{{ $t('components.businesses.form.renewalDateTime') }}</span></td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">
                        {{timestampToDateTime(business)}}
                      </td>
                    </tr>
                    <tr v-if="subscriptionInfo[business.businessId]?.secondsToExpiration">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2"><span class="title text-base">{{ $t('components.businesses.form.trial') }}</span></td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">{{Math.round(subscriptionInfo[business.businessId].secondsToExpiration / (24 * 3600))}}</td>
                    </tr>
                    <tr v-if="counterResources[business.businessId]">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2"><span class="title text-base">{{ $t('components.businesses.form.resourcesCallCredit') }}</span></td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">{{creditsToMinutes(business, counterResources[business.businessId]['CALLCREDIT'])}}</td>
                    </tr>
                    <!--
                    <tr v-if="counterResources[business.businessId]">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2"><span class="title text-base">{{ $t('components.businesses.form.resourcesCallCount') }}</span></td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">{{counterResources[business.businessId]['CALL']}}</td>
                    </tr>
                    -->
                    <tr v-if="counterResources[business.businessId]">
                      <td class="border-bottom-1 table-separator-bottom font-bold py-2"><span class="title text-base">{{ $t('components.businesses.form.resourcesSmsCount') }}</span></td>
                      <td class="border-bottom-1 table-separator-bottom py-2 align-right-td">{{counterResources[business.businessId]['SMS']}}</td>
                    </tr>
                  </table>
                </div>

                <div class="md:hidden block list-group">
                  <template v-if="business.businessId && isAdmin">
                    <div class="list-group-item border-top-1 table-separator-bottom text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.businessId') }}</p>
                      <span>{{ business.businessId }}</span>
                    </div>
                  </template>
                  <template v-if="business.nickname">
                    <div class="list-group-item border-top-1 table-separator-bottom text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.nickname') }}</p>
                      <span>{{ business.nickname }}</span>
                    </div>
                  </template>

                  <template v-if="business.emailAddress">
                    <div class="list-group-item text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.email') }}</p>
                      <span>{{ business.emailAddress }}</span>
                    </div>
                  </template>

                  <template v-if="business.businessPhoneNumber">
                    <div class="list-group-item text-center">
                      <p class="mb-2">
                        <span class="title text-base">{{ $t('components.businesses.form.businessPhoneNumber') }}</span>
                        <span class="title text-base">
                    {{ isBusinesPhoneNumberVerified(business) ? '' :
                            ' (' + $t('components.businesses.businessPhoneNumberVerified') + ')' }}
                  </span>
                      </p>
                      <span>{{ business.businessPhoneNumber }}</span>
                    </div>
                  </template>

                  <template v-if="business.serviceNumber">
                    <div class="list-group-item text-center">
                      <div v-if="isWebView">
                        <p class="title text-base mb-2">
                          {{ $t('components.businesses.form.serviceNumber') }}
                          {{ isTemporaryTestServiceNumber(business) ? ' (' + $t('components.businesses.enabledForTest') + ')' : '' }}
                        </p>
                      </div>
                      <div v-else>
                        <p class="title text-base mb-2">
                          {{ $t('components.businesses.form.serviceNumber') }}
                          {{ isTemporaryTestServiceNumber(business) ? ' (' + $t('components.businesses.onlyForTest') + ')' : '' }}
                        </p>
                      </div>

                      <div v-if="isWebView">
                        <b>{{ getCleanServiceNumber('', business.serviceNumber) }}</b>
                      </div>
                      <div v-else>
                        <a :href="getCleanServiceNumber('tel:', business.serviceNumber)">
                          <b>{{ getCleanServiceNumber('', business.serviceNumber) }}</b>
                        </a>
                      </div>
                    </div>
                  </template>
                  <template v-if="business.template && templates[business.template]">
                    <div class="list-group-item text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.plan') }}</p>
                      <span class="title text-base">
                  {{ templates[business.template].humanName }}
                </span>
                    </div>
                  </template>
                  <template v-if="! isWebView && subscriptionInfo[business.businessId]?.status">
                    <div class="list-group-item text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.subscriptionStatus') }}</p>

                      <span class="title text-base">
                  {{ $t(`components.businesses.form.subscriptionStatusLabels.${subscriptionInfo[business.businessId].status}`) }}
                </span>
                    </div>
                  </template>
                  <template v-if="! isWebView && subscriptionInfo[business.businessId]?.status === 'ACTIVE' &&
                            ! subscriptionInfo[business.businessId]?.secondsToExpiration &&
                              subscriptionInfo[business.businessId]?.currentPeriodEnd">
                    <div class="list-group-item text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.renewalDateTime') }}</p>

                      <span class="title text-base">
                  {{timestampToDateTime(business)}}
                </span>
                    </div>
                  </template>
                  <template v-if="! isWebView && subscriptionInfo[business.businessId]?.secondsToExpiration">
                    <div class="list-group-item text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.trial') }}</p>
                      <span>{{ Math.round(subscriptionInfo[business.businessId].secondsToExpiration / (24 * 3600)) }}</span>
                    </div>
                  </template>
                  <template v-if="counterResources[business.businessId]">
                    <div class="list-group-item text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.resourcesCallCredit') }}</p>
                      <span>{{creditsToMinutes(business, counterResources[business.businessId]['CALLCREDIT'])}}</span>
                    </div>
                  </template>
                  <!--
                  <template v-if="counterResources[business.businessId]">
                    <div class="list-group-item text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.resourcesCallCount') }}</p>
                      <span>{{counterResources[business.businessId]['CALL']}}</span>
                    </div>
                  </template>
                  -->
                  <template v-if="counterResources[business.businessId]">
                    <div class="list-group-item text-center">
                      <p class="title text-base mb-2">{{ $t('components.businesses.form.resourcesSmsCount') }}</p>
                      <span>{{counterResources[business.businessId]['SMS']}}</span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div  v-if="user" class="business-button-panel text-center">
              <!--
              <div v-tooltip="{ value: $t('components.businesses.selectButtonTooltip.disabled'),  disabled: isBusinesPhoneNumberVerified(business) || isAdmin }">
              -->
              <Button
                  v-if="! isWebView" iconPos="right"
                  @click="router.push(`/conversations?id=${business.businessId}&${business.languageCountry.replace('_', '-')}`)"
                  icon="pi pi-fw pi-table" :label="$t('components.businesses.conversationsViewButtonLabel')"
              />
              <Button
                  :label="$t('components.businesses.editAssistant')" icon="pi pi-pencil" iconPos="right"
                  @click="router.push(`/businessconfiguration?id=${business.businessId}`)"
              />
              <!--
              <Button
                  :label="$t('components.businesses.editAssistant') + ' (legacy)'" icon="pi pi-pencil" iconPos="right"
                  @click="selectBusiness(key)"
              />
              -->
              <Button
                  v-if="! isWebView"
                  :label="$t('components.businesses.planSubscription')" icon="pi pi-ticket" iconPos="right"
                  @click="editPlan(key)"
              />
              <Button
                  v-if="enableSubscriptionButton" :label="$t('components.businesses.plan')" icon="pi pi-ticket" iconPos="right"
                  @click="editSubscription(key)"
              />
              <Button
                  v-if="! isTemporaryTestServiceNumber(business)" iconPos="right"
                  @click="setBusinessAndOpenForwarding(key)"
                  icon="pi pi-fw pi-info-circle" :label="$t('components.businesses.gotoCallForwardingInstructionsButtonLabel')"
              />
              <Button
                  v-if="customerRecord.data?.stripePartnerPromotionCode" iconPos="right"
                  :label="$t('components.businesses.partnerProgramButtonLabel')" icon="pi pi-users"
                  @click="router.push('/partnerprogram?code=' + customerRecord.data?.stripePartnerPromotionCode)"
              />
              <!--
              <Button
                  v-if="true" iconPos="right"
                  :label="$t('components.businesses.invoicingButtonLabel')" icon="pi pi-building"
                  @click="selectBusiness(key, {'at': 'vat'})"
              />
              -->
              <Button
                  v-if="business.variables.MRZAPPA_ENABLED"
                  iconPos="right"
                  label="WhatsApp Connect" icon="pi pi-whatsapp"
                  @click="router.push('/whatsappweb?businessId=' + business.businessId)"
              />
            </div>
          </div>
          <!--
          <div v-if="index < businesses.size -1" class="separator"></div>
          -->
        </div>
      </div>
    </div>
    <Paginator
        :template="{
                '640px': 'PrevPageLink CurrentPageReport NextPageLink',
        '960px': 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink',
        '1300px': 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown RowsPerPageDropdown'
    }"
        :alwaysShow="false"
        :rows="size"
        v-model:first="first"
        :rowsPerPageOptions="[10,20,30,50,100]"
        :totalRecords="totalHits"
        @page="onPage($event)">
    </Paginator>
  </div>
</template>

<script>
import {computed, ref} from 'vue'
import {useStore} from 'vuex'
import axios from "axios"
import router from "@/router"
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/firebase/config";
//import Plans from '@/components/Plans'

export default {
  components: {
    //Plans
  },
  updated() {
  },
  setup: () => {
    return {
    }
  },
  data: () => {
    const store = useStore()
    const sortOptions = [
      {name: 'Ascending', value: 'ASC'},
      {name: 'Descending', value: 'DESC'}
    ];
    const selectedSortOption = ref("DESC");

    const subscriptionStatusValueLabelMap = [
      {name: "creationDateTime", code: "creationDateTime"},
      {name: "companyName", code: "companyName"},
      {name: "delete", code: "delete"},
      {name: "emailAddress", code: "emailAddress"},
      {name: "lastUpdateDateTime", code: "lastUpdateDateTime"},
      {name: "nickname", code: "nickname"},
      {name: "onboarding", code: "onboarding"},
      {name: "subscriptionStatus", code: "subscriptionStatus"},
      {name: "template", code: "template"},
      {name: "trialExpirationDatetime", code: "trialExpirationDatetime"}
    ];
    const selectedSubscriptionStatus = ref([{name: "creationDateTime", code: "creationDateTime"}]);

    const matchFieldMap = [
      {name: "Nickname", value: "nickname"},
      {name: "Business Name", value: "companyName"},
      {name: "Email", value: "emailAddress"}
    ];
    const matchFieldMapValue = ref("companyName");

    const customerRecord = ref({})
    const isWebView = computed(() => store.state.isWebview)
    const osName = computed(() => store.state.webviewOsName)
    const isAdmin = computed(() => store.state.role === 'admin')
    return {
      languageCountry: "it-IT",
      store,
      isWebView,
      isAdmin,
      osName,
      router,
      customerRecord,
      enableSubscriptionButton: false,
      user: computed(() => store.state.user),
      authIsReady: computed(() => store.state.authIsReady),
      showProgressBar: false,
      from: 0,
      totalHits: 0,
      size: 10,
      first: 1,
      counterResources: {},
      subscriptionInfo: {},
      businesses: {},
      templates: {},
      selectedSubscriptionStatus,
      subscriptionStatusValueLabelMap,
      sortOptions,
      selectedSortOption,
      matchFieldMap,
      matchFieldMapValue,
      matchFieldMapQueryValue: null,
      queryAdditionalParameters: {},
      sortFields: ['creationDateTime']
    }
  },
  methods: {
    showBusinessOverlay(business) {
      return !this.isAdmin && business.subscriptionStatus === 'TEST'
    },
    onUpdateMatchFieldMapQueryValue() {
      if(this.matchFieldMapValue && this.matchFieldMapQueryValue) {
        this.queryAdditionalParameters = {}
        this.queryAdditionalParameters[this.matchFieldMapValue] =
            this.matchFieldMapQueryValue.replace(/^%/, "").replace(/%$/, "").replace(/^/, "%").replace(/$/, "%");
      } else {
        this.queryAdditionalParameters = {}
      }
      this.fetchBusinessList(this.getBusinessSubscriptionInfo, this.fetchBusinessResources)
    },
    onSortFieldsChange(event) {
      this.sortFields = event.value.map(v => v.code)
      this.fetchBusinessList(this.getBusinessSubscriptionInfo, this.fetchBusinessResources)
    },
    onSortOrderChange(event) {
      this.fetchBusinessList(this.getBusinessSubscriptionInfo, this.fetchBusinessResources)
    },
    onPage(event) {
      //{page: 3, first: 30, rows: 10, pageCount: 6}
      this.from = event.first
      this.size = event.rows
      this.fetchBusinessList(this.getBusinessSubscriptionInfo, this.fetchBusinessResources)
    },
    isBusinesPhoneNumberVerified(business) {
      //console.debug(business.tested)
      return business.tested && !(business.tested === "1970-01-01T00:00:00")
    },
    isTemporaryTestServiceNumber(business) {
      const snParts = business.serviceNumber.split("#")
      return !!snParts[1];
    },
    getCleanServiceNumber(prefix, serviceNumber) {
      if(serviceNumber) {
        const sn = serviceNumber.replace(/#.*$/, '')
        if(prefix && sn)
          return prefix + sn
        else
          return sn
      } else {
        return ""
      }
    },
    timestampToDateTime(business) {
      const currentPeriodEndTsMillis = this.subscriptionInfo[business.businessId].currentPeriodEnd * 1000
      const date = new Date(currentPeriodEndTsMillis)
      const localizedDate = date.toLocaleString(business.languageCountry, {timeZone: business.timezoneStr })
      return localizedDate
    },
    createBusiness() {
      router.push({
        name: "OnboardingLanguage", //new onboarding
        params: {
        }
      })
    },
    setBusinessAndOpenForwarding(idx) {
      const selectedBusiness = this.businesses.get(idx)
      const onboardingData = {
        business: selectedBusiness,
        enableBackwardButton: true
      }
      this.store.commit('setOnboardingData', onboardingData)
      router.push({
        name: "OnboardingChooseDevice",
        params: {
        }
      })
    },
    selectBusiness(idx, query = {}) {
      const selectedBusiness = this.businesses.get(idx)
      this.store.commit('setSelectedBusiness', selectedBusiness)
      router.push({
        name: "Business",
        query: query
      })
    },
    editPlan(idx) {
      const selectedBusiness = this.businesses.get(idx)
      if(selectedBusiness.template === "generic_onboarding" || selectedBusiness.template === "generic" || selectedBusiness.onboarding) {
        const onboardingData = {
          business: selectedBusiness,
          enableBackwardButton: true
        }
        this.store.commit('setOnboardingData', onboardingData)
        router.push({
          name: "OnboardingChoosePlan",
          params: {
          }
        })
      } else {
        this.store.commit('setSelectedBusiness', selectedBusiness)
        router.push({
          name: "Plan",
          params: {}
        })
      }
    },
    editSubscription(idx) {
      const selectedBusiness = this.businesses.get(idx)
      this.store.commit('setSelectedBusiness', selectedBusiness)
      router.push("/subscription")
    },
    async getCustomerRecord(){
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      }
      const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/customer/registry?id=" + this.user.uid
      axios.get(url,
          {
            headers: headers
          }
      ).then((response) => {
        if(response.data) {
          this.customerRecord = response.data
        } else {
          this.customerRecord = {}
        }
        console.debug("CustomerRecord:",  this.customerRecord)
      }).catch((error) => {
        console.error(error)
      })
    },
    creditsToMinutes(business, callcredits) {
      const callCreditFactorsStr = business.variables["CALLCREDIT_FACTOR"] ?? '{}' ;
      const callCreditFactors = JSON.parse(callCreditFactorsStr) ;
      const factor = callCreditFactors[business.template] ?? 4 ;
      return Math.floor(callcredits / factor)
    },
    async fetchBusinessResources(businessId, subscriptionStatus, category) {
      console.log("Called fetchBusinessResources(" + businessId + ", " + category + ")")
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      }
      const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/business/resources/count"

      let subcategoryExclude = ["900-WATERMARK"]
      let subcategory = []
      if(subscriptionStatus === "TEST") {
        subcategory.push("400-TEST")
      } else {
        subcategoryExclude.push("400-TEST")
      }
      axios.post(url,
          {
            businessId: businessId,
            category: category,
            subcategory: subcategory,
            subcategoryExclude: subcategoryExclude
          },
          {
            headers: headers
          }
      ).then((response) => {
        console.debug("Counter:", category, response.data)
        if(response.data >= 0) {
          this.counterResources[businessId] = this.counterResources[businessId] || {}
          this.counterResources[businessId][category] = response.data
        } else {
          this.counterResources[businessId] = this.counterResources[businessId] || {}
          this.counterResources[businessId][category] = undefined
        }
        //console.debug("Resources", this.counterResources)
      }).catch((error) => {
        console.error(error)
      })
    },
    async getBusinessSubscriptionInfo(businessId) {
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      }
      const url = process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/business/subscription?id=" + businessId
      await axios.get(url,
          {
            headers: headers
          }
      ).then((response) => {
        console.log("Subscription:", response.data)
        if(response.data) {
          this.subscriptionInfo[businessId] = response.data
        } else {
          this.subscriptionInfo[businessId] = undefined
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    async fetchBusinessList(subscriptionFunction, fetchBusinessResourcesFunction) {
      const self = this
      self.showProgressBar = true;
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      }

      const request = {
        offset: self.from,
        limit: self.size,
        orderBy: self.sortFields,
        sortOrder: self.selectedSortOption,
        ...self.queryAdditionalParameters
      }
      axios.post(process.env.VUE_APP_STARCHAT_URL + "/mrcall/v1/talkmeapp0/crm/business/search",
          request,
          {
            headers: headers
          }
      ).then(response => {
        if(response.headers["x-mrcall-role"] === "admin") {
          this.store.commit('setRole', 'admin')
        } else {
          this.store.commit('setRole', 'owner')
        }
        const map = new Map()
        if(response.data && response.data.length !== 0) {
          //sorted by tested timestamp
          self.totalHits = response.data[0].totalHits
          response.data.map(function(item) {
            const tested = item.creationDateTime
            return [tested, item]
          }).sort((a, b) => a[0] <= b[0]).forEach(function (item) {
            map.set(item[1].businessId, item[1])
          })

          // Unsorted map
          response.data.forEach(async function (item) {
            await self.getBusinessSubscriptionInfo(item.businessId)
            self.fetchBusinessResources(item.businessId, item.subscriptionStatus, "CALLCREDIT")
            self.fetchBusinessResources(item.businessId, item.subscriptionStatus, "CALL")
            self.fetchBusinessResources(item.businessId, item.subscriptionStatus, "SMS")
            map.set(item.businessId, item)
          })
        } else {
          self.showProgressBar = false
          if(! self.isAdmin) {
            self.createBusiness()
          }
        }
        //console.debug("RESULT: ", response.data)
        //console.debug("RESULTMAP: ", map)
        this.businesses = map
        this.showProgressBar = false
      }).catch((error) => {
        console.error(error)
        this.showProgressBar = false
        if(error.response.status === 401) {
          this.store.dispatch('logout')
          router.replace('/login')
        }
      })
    },
    async templateList() {
      this.showProgressBar = true;
      let headers = {
        "Content-type": "application/json; charset=UTF-8",
        "auth": this.user.accessToken
      };
      axios.get(process.env.VUE_APP_STARCHAT_URL +
          `/mrcall/v1/talkmeapp0/crm/template?language=${this.languageCountry}`,
          {
            headers: headers
          }
      ).then((response) => {
        if(response.headers["x-mrcall-role"] === "admin") {
          this.store.commit('setRole', 'admin')
        } else {
          this.store.commit('setRole', 'owner')
        }
        this.templates = {}
        let refTemplate = this.templates
        if(response.data) {
          response.data.forEach(function (item) {
            console.log(item.name, item)
            refTemplate[item.name] = item
          })
        }
        this.showProgressBar = false
      }).catch((error) => {
        console.error("Fetching TemplateList: ", error);
        this.showProgressBar = false
        if(error.response?.status && error.response.status === 401) {
          this.store.dispatch('logout')
          router.push('/login')
        }
      })
    }
  },
  watch: {
  },
  computed: {
  },
  mounted() {
    this.store.commit('setCreateBusinessTemplate', null)
    if(! this.store.state.user) {
      onAuthStateChanged(auth, (user) => {
        if (user && user.emailVerified && !user.isAnonymous) {
          console.debug("UserStateChanged:", user)
          this.fetchBusinessList()
          this.getCustomerRecord()
        } /*else {
          router.replace('/login')
        }*/
      })
    } else {
      let successPayment = false ;
      if(this.$route.query.success_payment) {
        successPayment = JSON.parse(this.$route.query.success_payment) ;
      }
      let sessionReference = "anonymous"
      if(this.$route.query.session_reference) {
        sessionReference = this.$route.query.session_reference ;
      }
      if(successPayment && sessionReference) {
        this.$gtag.event("payment_success", {
          'user_email': this.store.state.user.email,
          'session_reference': sessionReference
        });
      }
      this.templateList()
      this.fetchBusinessList()
      this.getCustomerRecord()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/colors';
@import '../assets/style/fonts';

/*
.separator {
  padding-bottom: 2em;
  margin-bottom: 2em;
  border-bottom-color: @mrcall_blue;
  border-bottom-width: medium;
}
 */

.table-separator-top {
  border-color: @mrcall_black;
}

.table-separator-bottom {
  border-color: @mrcall_light_grey_2;
}

.align-right-td {
  text-align: right;
}
.table-odd-tr {
  background-color: @mrcall_light_grey_2;
  overflow-wrap: break-word;
}

.p-button {
  margin-right: .5rem;
}

.p-buttonset {
  .p-button {
    margin-right: 0;
  }
}

.sizes {
  .button {
    margin-bottom: .5rem;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .p-button {
    margin-bottom: .5rem;

    &:not(.p-button-icon-only) {
      display: flex;
      width: 100%;
    }
  }

  .p-buttonset {
    .p-button {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 640px) {
  .businesses-toolbar {
    max-width: 100%;
  }
}

@media screen and (min-width: 640px) {
  .businesses-toolbar {
    max-width: 640px;
  }
}
</style>
