// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/mrcall/icons/man_with_laptop.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.inputboxtitle[data-v-5d0d0a90] {
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 1em 0 0.4em 0;
  color: #495057;
}
.inputboxsubtitle[data-v-5d0d0a90] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 0.4em 0 0 0;
  color: #495057;
}
.inputboxsubtitle[data-v-5d0d0a90] a:link {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-5d0d0a90] a:visited {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-5d0d0a90] a:hover {
  color: #0F110C;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-5d0d0a90] a:active {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxerror[data-v-5d0d0a90] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 0.4em 0 0 0;
  color: red;
}
.item[data-v-5d0d0a90] {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  --maz-font-family: 'Inter', serif, 'primeicons';
  --maz-border-radius: 3px;
  --maz-border-width: 1px;
}
.item .p-component[data-v-5d0d0a90] {
  width: 100%;
}
#footer[data-v-5d0d0a90] {
  display: flex;
}
@media screen and (max-width: 640px) {
#footer[data-v-5d0d0a90] {
    flex-direction: column;
}
}
@media screen and (min-width: 640px) {
#footer[data-v-5d0d0a90] {
    flex-direction: row;
}
}
#footer .p-button[data-v-5d0d0a90] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
  margin: 0.2em 0.2em 0.2em auto;
}
@media screen and (min-width: 640px) {
#footer .button-left[data-v-5d0d0a90] {
    margin-right: auto;
    padding-right: 1em;
}
}
#footer .button-left .p-button-left.p-button[data-v-5d0d0a90] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 1em;
  color: #6C757D;
  background: transparent;
  border: 1px solid #6C757D;
  padding: 0.5rem 1em;
  margin: 0.2em 0.2em 0.2em auto;
  border-radius: 33px;
}
#footer .button-left .p-button-left.p-button[data-v-5d0d0a90]:hover {
  background: #6C757D;
  color: #FFFFFF;
}
.item[data-v-5d0d0a90] {
  padding: 1em 0 0 0 ;
  margin: auto;
}
.central-icon[data-v-5d0d0a90] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  margin-left: auto;
  margin-right: auto;
  width: 10em;
  height: 8em;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
