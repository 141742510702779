// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.signin-signup-form[data-v-8d60450a] {
  width: 100%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}
.signin-signup-form .title[data-v-8d60450a] {
  font-size: 1.5rem !important;
}
.signin-signup-form .p-button[data-v-8d60450a] {
  width: 100%;
}
.signin-signup-form .p-field[data-v-8d60450a] {
  margin: 1rem 0 1rem 0;
}
.signin-signup-form .inputboxtitle[data-v-8d60450a] {
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  flex-grow: 1;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 1em 0px;
  color: #495057;
}
.p-tag[data-v-8d60450a] {
  background: #3F413D;
}
#signup-part .p-button[data-v-8d60450a] {
  background: #0F110C;
  border: 1px solid #0F110C;
  font-weight: bold;
  color: #F7941F;
}
#signup-part .p-button[data-v-8d60450a]:hover {
  background: #F7941F;
  color: #0F110C;
}
.account-section[data-v-8d60450a] {
  max-width: 600px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
