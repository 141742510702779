// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.inputboxtitle[data-v-e3b5f5fe] {
  /* body/semibold-600/line-height-auto */
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 1em 0 0.4em 0;
  color: #495057;
}
.inputboxsubtitle[data-v-e3b5f5fe] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 0.4em 0 0 0;
  color: #495057;
}
.inputboxsubtitle[data-v-e3b5f5fe] a:link {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-e3b5f5fe] a:visited {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-e3b5f5fe] a:hover {
  color: #0F110C;
  background-color: transparent;
  text-decoration: underline;
  font-weight: 600;
  font-size: 1em;
}
.inputboxsubtitle[data-v-e3b5f5fe] a:active {
  color: #0068FF;
  background-color: transparent;
  text-decoration: none;
  font-weight: 600;
  font-size: 1em;
}
.inputboxerror[data-v-e3b5f5fe] {
  font-family: 'Inter', serif, 'primeicons';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1em;
  /* identical to box height */
  /* Lara/Global/textColor */
  padding: 0.4em 0 0 0;
  color: red;
}
.pageblock[data-v-e3b5f5fe] {
  padding: 2em;
  border: 1px;
  margin: 0 0 1em 0;
  border-radius: 6px;
  border-color: #DFE7EF;
  border-style: solid;
  --maz-font-family: 'Inter', serif, 'primeicons';
  --maz-border-radius: 3px;
  --maz-border-width: 1px;
}
.pageblock-noborder[data-v-e3b5f5fe] {
  padding: 2em;
  border: 0;
  margin: 0 0 1em 0;
  --maz-font-family: 'Inter', serif, 'primeicons';
  --maz-border-radius: 3px;
  --maz-border-width: 1px;
}
.header_buttonbar[data-v-e3b5f5fe] {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.footer_buttonbar[data-v-e3b5f5fe] {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.tiered-menu-wrapper[data-v-e3b5f5fe] {
  overflow: scroll;
}
@media screen and (max-width: 640px) {
.pageblock-noborder[data-v-e3b5f5fe] {
    padding: 0.5em;
}
.pageblock[data-v-e3b5f5fe] {
    padding: 0.5em;
}
}
.mrCallLogo[data-v-e3b5f5fe] {
  width: 12rem;
  height: 4rem;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
