// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-button[data-v-65549f94] {
  margin-right: 0.5rem;
}
.card-not-selected[data-v-65549f94] {
  margin-left: 1em ;
  margin-right: 1em ;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
