// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/mrcall/littleman/assistance_transparent_bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
#onboarding-page[data-v-314717a2] {
  padding: 4em 2em 4em 2em ;
  display: flex ;
  flex-direction: row;
  height: 100%;
}
#onboarding-page .p-divider-vertical.p-divider-center[data-v-314717a2] {
  /* align-items: center; */
  /* border-color: black; */
  background: #3F413D;
  /* border: none; */
  width: 1px;
  /* clear: none; */
  /* height: 22px; */
  margin: 0;
  /* box-shadow: none; */
  padding: 0 ;
}
#onboarding-page #mrcall-onboarding-content[data-v-314717a2] {
  height: -moz-max-content;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: initial;
}
@media screen and (max-width: 640px) {
#onboarding-page #mrcall-onboarding-content[data-v-314717a2] {
    background: #FFFFFF;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section[data-v-314717a2] {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    margin-bottom: auto;
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    /* Lara/Root/surface-card */
    background: #FFFFFF;
    border-radius: 6px;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section #central-header-section[data-v-314717a2] {
    text-align: center;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section #central-header-section #title[data-v-314717a2] {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1em;
    padding-bottom: 0.5em;
    color: #282E38;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section #central-header-section #subtitle[data-v-314717a2] {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1em;
    color: #495057;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section #content[data-v-314717a2] {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
}
@media screen and (min-width: 640px) {
#onboarding-page #mrcall-onboarding-content[data-v-314717a2] {
    background: #E5E5E5;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section[data-v-314717a2] {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
    width: 556px;
    min-height: 510px;
    height: auto;
    left: calc(50% - 556px / 2 - 0.32px);
    /* Lara/Root/surface-card */
    background: #FFFFFF;
    /* cardShadow */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section #central-header-section[data-v-314717a2] {
    text-align: center;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section #central-header-section #title[data-v-314717a2] {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1em;
    padding-bottom: 0.5em;
    color: #282E38;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section #central-header-section #subtitle[data-v-314717a2] {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5em;
    color: #495057;
}
#onboarding-page #mrcall-onboarding-content #onboarding-central-section #content[data-v-314717a2] {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
}
#onboarding-page #divider[data-v-314717a2] {
  padding-left: 1em;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #6C757D;
}
#onboarding-page #footer[data-v-314717a2] {
  width: 100%;
  display: flex;
}
@media screen and (max-width: 640px) {
#onboarding-page #footer[data-v-314717a2] {
    flex-direction: column;
}
}
@media screen and (min-width: 640px) {
#onboarding-page #footer[data-v-314717a2] {
    flex-direction: row;
}
}
#onboarding-page #support-large[data-v-314717a2] {
  padding: 0 0 0 2em;
  display: flex ;
  flex-direction: row;
}
#onboarding-page #support-small #header-assistance-icon[data-v-314717a2] {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
  width: 48px;
  height: 48px;
  mix-blend-mode: multiply;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
#onboarding-page #support-small .p-component[data-v-314717a2] {
  width: 100%;
}
@media screen and (max-width: 640px) {
#onboarding-page #support-large[data-v-314717a2] {
    display: none;
}
#onboarding-page #support-small[data-v-314717a2] {
    display: flex;
}
}
@media screen and (min-width: 640px) {
#onboarding-page #support-large[data-v-314717a2] {
    display: flex;
}
#onboarding-page #support-small[data-v-314717a2] {
    display: none;
}
}
@media screen and (max-width: 640px) {
#onboarding-page[data-v-314717a2] {
    height: 100% ;
    padding: 0;
    display: flex;
    flex-direction: column;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
