// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* font imports */
/*
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/icon?family=Inter');

*/
/* Montserrat - come font principale per i titoli e bodycopy */
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16WXZ0oJC8MLnbtrVK.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16WXh0oJC8MLnbtg.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v24/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Noto Serif Display SemiCondensed Bold Italic - per i titoli in italico */
/*
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display&query=noto+serif');
*/
/* cyrillic-ext */
@font-face {
  font-family: 'Noto Serif Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/notoserifdisplay/v15/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugAYjIfg-zDkf4QrQ.woff) format('woff');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Noto Serif Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/notoserifdisplay/v15/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugJYjIfg-zDkf4QrQ.woff) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Noto Serif Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/notoserifdisplay/v15/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugBYjIfg-zDkf4QrQ.woff) format('woff');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Noto Serif Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/notoserifdisplay/v15/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugOYjIfg-zDkf4QrQ.woff) format('woff');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Noto Serif Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/notoserifdisplay/v15/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugCYjIfg-zDkf4QrQ.woff) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Serif Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/notoserifdisplay/v15/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugDYjIfg-zDkf4QrQ.woff) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Serif Display';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(https://fonts.gstatic.com/s/notoserifdisplay/v15/buERppa9f8_vkXaZLAgP0G5Wi6QmA1QaeYah2sovLCDq_ZgLyt3idQfktOG-PVpd4ugNYjIfg-zDkf4.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.italic-font[data-v-495ce954] {
  font-family: 'Noto Serif Display', serif;
  font-style: italic ;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZJhiJ-Ek-_EeAmM.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZthiJ-Ek-_EeAmM.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZNhiJ-Ek-_EeAmM.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZxhiJ-Ek-_EeAmM.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZBhiJ-Ek-_EeAmM.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZFhiJ-Ek-_EeAmM.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfAZ9hiJ-Ek-_EeA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* PRINCIPALS */
/* SECONDARY */
/* ACCESSORY */
/* TEMPLATES */
.business-page .p-fieldset[data-v-495ce954] .p-fieldset-legend {
  width: 100%;
}
.business-page .p-fieldset[data-v-495ce954] .p-fieldset-legend a {
  width: -moz-fit-content;
  width: fit-content;
}
.business-page .p-inputtext[data-v-495ce954] {
  width: 100%;
}
.business-page .p-dropdown[data-v-495ce954] {
  width: 100%;
}
.business-page .country-item[data-v-495ce954] {
  width: -moz-fit-content;
  width: fit-content;
}
.business-page .country-item img[data-v-495ce954] {
  width: 17px;
  margin-right: 0.5rem;
}
.business-page .p-button[data-v-495ce954] {
  margin-right: 0.5rem;
}
.business-page .p-buttonset[data-v-495ce954] {
  display: flex;
}
.business-page .p-buttonset .p-button[data-v-495ce954] {
  margin-right: 0;
  border-top-right-radius: 25px!important;
  border-top-left-radius: 25px!important;
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 0!important;
  width: 200px;
}
.business-page .p-buttonset .p-button + .p-button[data-v-495ce954] {
  margin-left: 1rem;
}
.business-page .sizes .button[data-v-495ce954] {
  margin-bottom: 0.5rem;
  display: block;
}
.business-page .sizes .button[data-v-495ce954]:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 640px) {
.business-page .p-button[data-v-495ce954] {
    margin-bottom: 0.5rem;
}
.business-page .p-button[data-v-495ce954]:not(.p-button-icon-only) {
    display: flex;
    width: 100%;
}
.business-page .p-buttonset .p-button[data-v-495ce954] {
    margin-bottom: 0;
}
}
.business-page .p-inputtext[data-v-495ce954] {
  border-radius: 33px;
  padding: 1rem 1.5rem;
}
.business-page .search-container .p-button[data-v-495ce954] {
  padding: 0.813rem 1.5rem;
}
.business-page .m-phone-number-input__input .m-input-wrapper[data-v-495ce954] {
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  border-width: 1px;
}
.business-page .m-phone-number-input__select .m-input-wrapper[data-v-495ce954] {
  border-top-left-radius: 33px;
  border-bottom-left-radius: 33px;
  border-width: 1px;
}
@media screen and (max-width: 640px) {
.business-page .formrow[data-v-495ce954] {
    padding-left: 0;
    padding-right: 0;
}
}
.business-page .p-tabview[data-v-495ce954] {
  width: 50rem;
}
.business-page .p-tabview .p-tabview-nav[data-v-495ce954] {
  display: FLEX;
  justify-content: center;
  border-color: black;
  padding-bottom: 0.15rem;
}
.business-page .p-tabview .p-tabview-nav li[data-v-495ce954] {
  width: 200px;
}
.business-page .p-tabview .p-tabview-nav li a[data-v-495ce954] {
  display: block;
  text-align: center;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: #0068FF;
  border: 1px solid #0068FF;
  color: white;
  border-bottom: none;
  font-size: 1.3em;
  padding: 0.5rem;
}
.business-page .p-tabview .p-tabview-nav li + li[data-v-495ce954] {
  margin-left: 1rem;
}
.business-page .p-tabview .p-tabview-nav li:not(.p-highlight) a[data-v-495ce954]:hover {
  background: #D7DCE2 !important;
  color: black!important;
  border-color: #D7DCE2 !important;
}
.business-page .p-tabview .p-tabview-nav li.p-highlight a[data-v-495ce954] {
  background: #2196F3;
  border-color: #2196F3;
  color: #ffffff;
}
.business-page .p-tabview .p-tabview-nav li.p-highlight a[data-v-495ce954]:hover {
  background: #2196F3;
  border-color: #2196F3;
  color: #ffffff;
}
.line-clamp-3[data-v-495ce954] {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.line-clamp-2[data-v-495ce954] {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.business[data-v-495ce954] {
  padding: 0.3em 0.3em 2em 0.3em;
  margin-bottom: 2em;
  border-bottom-color: #0068FF;
  border-bottom-width: medium;
}
.business .title.line-clamp[data-v-495ce954] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}
@media screen and (max-width: 640px) {
.business .title.line-clamp[data-v-495ce954] {
    -webkit-line-clamp: 2;
}
}
.business .upgradeInformation[data-v-495ce954] {
  color: #3F413D;
}
.business .upgradeInformation.line-clamp[data-v-495ce954] {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}
@media screen and (max-width: 640px) {
.business .upgradeInformation.line-clamp[data-v-495ce954] {
    -webkit-line-clamp: 2;
}
}
.business .p-inputtext[data-v-495ce954] {
  border-radius: 33px;
  padding: 1rem 1.5rem;
}
.business .m-phone-number-input__input .m-input-wrapper[data-v-495ce954] {
  border-top-right-radius: 33px;
  border-bottom-right-radius: 33px;
  border-width: 1px;
}
.business .m-phone-number-input__select .m-input-wrapper[data-v-495ce954] {
  border-top-left-radius: 33px;
  border-bottom-left-radius: 33px;
  border-width: 1px;
}
.business .p-dropdown[data-v-495ce954] {
  border-radius: 33px;
}
.business .p-tabview-nav[data-v-495ce954] {
  display: flex;
  justify-content: center;
}
.business .central-information-panel[data-v-495ce954] {
  position: relative;
}
.business .central-information-panel .content[data-v-495ce954] {
  position: absolute;
}
.business .central-information-panel .overlay[data-v-495ce954] {
  background: #D7DCE2;
  opacity: 0.5;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.business-table-container[data-v-495ce954] {
  position: relative;
}
.business-table-card[data-v-495ce954] {
  background: #FFFFFF;
  border-radius: 6px 6px 0 0;
  margin: auto;
}
.business-table-card table[data-v-495ce954] {
  border-collapse: collapse;
}
.overlay-button-panel[data-v-495ce954] {
  margin: 0;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.business-table-card-overlay[data-v-495ce954] {
  position: absolute;
  background-color: hsla(212.72727273, 15.94202899%, 86.47058824%, 0.6);
  width: 100%;
  height: 100%;
  z-index: 99;
}
@media screen and (max-width: 640px) {
.business-table-card[data-v-495ce954] {
    max-width: 100%;
}
.business-table-card-overlay[data-v-495ce954] {
    max-width: 100%;
}
}
@media screen and (min-width: 640px) {
.business-table-card[data-v-495ce954] {
    max-width: 640px;
}
.business-table-card-overlay[data-v-495ce954] {
    max-width: 640px;
}
}
.grid-input-switch[data-v-495ce954] {
  position: relative;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}
.grid-input-switch .input-switch-container[data-v-495ce954] {
  position: relative;
  background: white;
}
.grid-input-switch .input-switch-container .p-inputswitch[data-v-495ce954] {
  height: 3.5rem;
  width: 5.875rem;
  z-index: 10;
  outline: none;
}
.grid-input-switch .input-switch-container .p-inputswitch .p-inputswitch-slider[data-v-495ce954]:before {
  width: 2.55rem;
  height: 2.55rem;
  margin-top: -1.25rem;
}
.grid-input-switch .input-switch-container .p-inputswitch.p-inputswitch-checked .p-inputswitch-slider[data-v-495ce954]:before {
  transform: translateX(2.75rem);
}
.business-phone-verification[data-v-495ce954] {
  max-width: 600px;
}
.business-content[data-v-495ce954] {
  max-width: 800px;
  margin: auto;
}
@media screen and (max-width: 640px) {
.business-content[data-v-495ce954] {
    margin: 2em auto auto auto;
    padding: 1em 0 1em 0 !important;
}
}
.onboarding-prepayment-container[data-v-495ce954] {
  max-width: 800px;
}
@media screen and (max-width: 640px) {
.onboarding-prepayment-container[data-v-495ce954] {
    max-width: 100%;
}
}
.payment-content-section[data-v-495ce954] {
  max-width: 800px;
}
@media screen and (max-width: 640px) {
.payment-content-section[data-v-495ce954] {
    max-width: 100%;
}
}
.list-group .list-group-item[data-v-495ce954] {
  padding: 1rem 0;
}
.list-group .list-group-item + .list-group-item[data-v-495ce954] {
  border-top: 1px solid #ced4da;
}
.list-group .list-group-item[data-v-495ce954]:last-child {
  border-bottom: 1px solid #ced4da;
}
.list-group .list-group-item[data-v-495ce954]:first-child {
  border-top: 1px solid #ced4da;
}
.p-dialog[data-v-495ce954] {
  border-radius: 33px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 33px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.p-dialog .p-dialog-header[data-v-495ce954] {
  border-top-right-radius: 33px;
  border-top-left-radius: 33px;
}
.p-dialog .p-dialog-footer[data-v-495ce954] {
  border-bottom-right-radius: 33px;
  border-bottom-left-radius: 33px;
}
.p-dialog h5[data-v-495ce954] {
  color: #0068FF;
}
/* base styles */
html[data-v-495ce954] {
  width: 100%;
}
body[data-v-495ce954] {
  font-family: 'Inter', serif;
  font-style: normal;
  margin: 0;
  padding: 0;
  border-style: none;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  display: flex !important;
  flex-direction: column !important;
  background: #E5E5E5;
  /*FIXME: to make nicer createsimple, but is to be removed once the new onboarding is in place*/
}
body .m-input-wrapper[data-v-495ce954] {
  width: 100%;
}
body .p-password[data-v-495ce954] {
  width: 100%;
}
body input[data-v-495ce954] {
  width: 100%;
}
body .p-button[data-v-495ce954] {
  font-family: 'Inter', serif;
  font-style: normal;
  color: #FFFFFF;
  background: #0068FF;
  border: 1px solid #0068FF;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 33px;
}
body .p-button[data-v-495ce954]:enabled:hover {
  color: #0068FF;
  background: #D7DCE2;
}
body .p-button[data-v-495ce954]:enabled:active {
  color: #0068FF;
  background: #D7DCE2;
}
body .business-page .p-tabview .p-tabview-nav[data-v-495ce954] {
  background: transparent;
}
body .signin-signup-form .link[data-v-495ce954] {
  margin-top: auto;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #0068FF;
  text-decoration: none;
}
body .signin-signup-form .link-smaller[data-v-495ce954] {
  margin-top: auto;
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 10.5px;
  line-height: 13px;
  color: #0068FF;
  text-decoration: none;
}
body .signin-signup-form .signin-signup-button-text[data-v-495ce954] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
body .signin-signup-form .companion-text[data-v-495ce954] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  /* Lara/Global/textColor */
  color: #495057;
}
body .signin-signup-form .companion-text-smaller[data-v-495ce954] {
  font-family: 'Inter', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10.5px;
  line-height: 13px;
  /* identical to box height */
  /* Lara/Global/textColor */
  color: #495057;
}
@media screen and (max-width: 640px) {
body[data-v-495ce954] {
    max-width: 100%;
}
body .main-page-content-section[data-v-495ce954] {
    margin: auto;
    padding-top: 1em;
    padding-bottom: 1em;
}
body .main-page-content-section .title[data-v-495ce954] {
    font-size: 1.4em;
    font-weight: bold;
    color: #0068FF;
}
body .main-page-content-section .title-white[data-v-495ce954] {
    font-size: 1.4em;
    font-weight: bold;
    color: #FFFFFF;
}
body .main-page-content-section .subtitle[data-v-495ce954] {
    font-size: 1em;
    font-weight: normal;
    color: #0068FF;
}
body .main-page-content-section .bold[data-v-495ce954] {
    font-weight: bold;
}
body .main-page-content-section p[data-v-495ce954] {
    font-size: 1.2em;
    font-weight: normal;
    color: #0F110C;
}
}
@media screen and (min-width: 640px) {
body .main-page-content-section[data-v-495ce954] {
    margin: auto;
    padding-left: 4em;
    padding-right: 4em;
    padding-top: 2em;
    padding-bottom: 2em;
}
body .main-page-content-section .title[data-v-495ce954] {
    font-size: 2.8em;
    font-weight: bold;
    color: #0068FF;
}
body .main-page-content-section .title-white[data-v-495ce954] {
    font-size: 2.8em;
    font-weight: bold;
    color: #FFFFFF;
}
body .main-page-content-section .subtitle[data-v-495ce954] {
    font-size: 2em;
    font-weight: normal;
    color: #0068FF;
}
body .main-page-content-section .bold[data-v-495ce954] {
    font-weight: bold;
}
body .main-page-content-section p[data-v-495ce954] {
    font-size: 1.4em;
    font-weight: normal;
    color: #0F110C;
}
}
body #app[data-v-495ce954] {
  border: 0;
  padding: 0;
  margin: 0;
}
body #app #app-container[data-v-495ce954] {
  position: absolute;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
body #app #app-container #navbar[data-v-495ce954] {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
body #app #app-container #app-central-content[data-v-495ce954] {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
}
[data-v-495ce954],[data-v-495ce954]::before,[data-v-495ce954]::after {
  margin: 0;
}
@media screen and (min-width: 640px) {
:root .p-button[data-v-495ce954] {
    margin: 0.2em;
}
}
:root .p-menubar .p-menubar-button[data-v-495ce954] {
  color: #0F110C;
  position: absolute;
  right: 2em;
}
:root .p-menubar-root-list[data-v-495ce954] {
  color: #0F110C;
  position: absolute;
  right: 2em;
}
:root .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon[data-v-495ce954] {
  color: #0F110C;
}
:root .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text[data-v-495ce954] {
  color: #0F110C;
}
:root .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link[data-v-495ce954]:not(.p-disabled):hover {
  background: #FFFFFF;
}
:root .p-menubar.p-menubar-mobile-active .p-menubar-root-list[data-v-495ce954] {
  background: #FFFFFF;
}
:root .center[data-v-495ce954] {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
:root .p-dialog .p-dialog-header[data-v-495ce954] {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
:root .p-dialog .p-dialog-footer[data-v-495ce954] {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
:root .p-dialog-header[data-v-495ce954] {
  display: flex ;
  flex-direction: row-reverse;
}
#app[data-v-495ce954] {
  flex-shrink: 0!important;
}
.main-page-content-section .highlight[data-v-495ce954] {
  font-weight: bold;
  color: #0068FF;
}
.cap-element[data-v-495ce954] {
  padding: 0.5em;
  background: #0068FF;
  border-top-left-radius: 25px ;
  border-top-right-radius: 25px;
  width: 100%;
}
.middle-element[data-v-495ce954] {
  text-align: left;
  padding: 0.5em;
  background: #CCE1FF;
  margin-top: 0.1em;
  overflow-wrap: break-word;
  width: 100%;
}
.before-closing-element[data-v-495ce954] {
  text-align: left;
  padding: 0.5em;
  background: #F1F3F7;
  margin-top: 0.1em;
  overflow-wrap: break-word;
  width: 100%;
}
.closing-element[data-v-495ce954] {
  text-align: left;
  padding: 0.5em;
  background: #F8D4A5;
  margin-top: 0.1em;
  overflow-wrap: break-word;
  width: 100%;
}
#pre-footer[data-v-495ce954] {
  margin-top: auto;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.center-restrict[data-v-495ce954] {
  max-width: 800px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6em;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
